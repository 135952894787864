import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react';
import { useFormik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import Botao from '../../components/Botao/Botao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './MovimentacoesEstoque.css';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import TabelaInterna from '../../components/Tabela/TabelaInterna';
import PesquisaProdutos from '../../components/PesquisaProdutos/PesquisaProdutos';

function EditarMovimentacoesEstoque() {
  const navigate = useNavigate();
  const [movimentacoesEstoques, setMovimentacoesEstoques] = useState({
    Data: '01/01/2024'
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetMovimentacoesEstoques() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditEstoques/Get', payload);
      if (res.status === 200) {
        let data = res.data.Estoque;
        data.Produtos = [];
        data.Data = new Date();
        console.log(data)
        setMovimentacoesEstoques(data);
        
        console.log(data);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetMovimentacoesEstoques();
  }, [id, alertTitle, alertStatus]);

  let save = async (mov, prods) => {
    setLoading(true);
    let payload = {
      data: prods,
      arg: mov,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditEstoques/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  let openAlert = (msg, status = 'success') => {
    setAlertStatus(status);
    setAlertTitle(msg);
    onOpen();
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={movimentacoesEstoques}
          onSubmit={async values => {
            setMovimentacoesEstoques(values);
            let produtos = values.Produtos;
            let movimentacao = values;
            delete movimentacao.Produtos;
            await save(JSON.stringify(produtos, null, 2),JSON.stringify(movimentacao, null, 2));
          }}
        >
          <Interno navigate={navigate} openAlert={openAlert} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate, openAlert }) {
  const formik = useFormikContext();
  let titulo = `${formik.values.Id !== '' ? 'Editar' : 'Nova'} Movimentação`;
  const optionsMovimentacoes = ['Entrada', 'Saída', 'Entre Depósitos'];

  async function handleAddProduto(nomeProduto) {
    let payload = {
      database: localStorage.getItem('database'),
      data: nomeProduto,
      arg: 1,
    };
    let res = await api.post('/ProdutoTracker/Get', payload);
    if (res.status === 200) {
      let data = res.data;
      console.log(data);
      addProdutosCarrinho(data);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  async function addProdutosCarrinho(produto) {
    let produtos = formik.values.Produtos;
    let movimentacao = formik.values;
    delete movimentacao.Produtos;
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(produtos, null, 2),
      arg: JSON.stringify(movimentacao, null, 2),
      arg2: JSON.stringify(produto, null, 2),
    };
    let res = await api.post('/EditEstoquesProdutos/AddProduto', payload);
    if (res.status === 200) {
      if (res.data.Success) {
        formik.setFieldValue('Produtos', res.data.Produtos);
      } else {
        openAlert(res.data.MSG, 'error');
      }
    } else {
      alert('Erro ao buscar dados');
    }
  }

  function removeProdutoCarrinho(produto) {
    var produtos = formik.values.Produtos;
    for (var i = 0; i < produtos.length; i++) {
      if (produtos[i].Produto.Nome === produto.Produto.Nome) {
        produtos.splice(i, 1);
        break;
      }
    }
    formik.setFieldValue('Produtos', produtos);
  }

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/MovimentacoesEstoque/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          {
            nome: 'Movimentações de Estoque',
            path: '/MovimentacoesEstoque/Listar',
          },
          { nome: titulo },
        ]}
      />
      <Card titulo={titulo}>
        <InputFormik
          titulo="Tipo"
          id="Tipo"
          name="Tipo"
          component={InputSelect}
          options={optionsMovimentacoes}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Data"
          id="Data"
          name="Data"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Estoque de Origem"
          id="DepositoOrigem"
          name="DepositoOrigem"
          component={InputAutoComplete}
          autocomplete={true}
          path={'Deposito'}
          database={localStorage.getItem('database')}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Estoque de Destino"
          id="DepositoDestino"
          name="DepositoDestino"
          component={InputAutoComplete}
          autocomplete={true}
          path={'Deposito'}
          database={localStorage.getItem('database')}
          gridColumn={gridColumn.gc4}
        />
      </Card>
      <Card titulo="Itens">
        <PesquisaProdutos funcao={handleAddProduto} />
        <TabelaInterna
          nomeLista="Produtos"
          columns={[
            {
              placeholder: 'Item',
              name: 'Descricao',
              type: 'text',
            },
            {
              placeholder: 'Quantidade',
              name: 'Quantidade',
              type: 'number',
              editavel: true,
            },
            {
              placeholder: 'Valor (R$)',
              name: 'ValorUnitario',
              type: 'money',
              editavel: true,
            },
            {
              placeholder: 'SubTotal (R$)',
              name: 'ValorTotal',
              type: 'money',
              editavel: true,
            },
            {
              placeholder: 'Com Lote',
              name: 'ComLote',
              type: 'toggle',
              editavel: true,
            },
            {
              placeholder: 'Lote',
              name: 'Lote',
              type: 'text',
              editavel: true,
            },
            {
              placeholder: 'Validade',
              name: 'Validade',
              type: 'date',
              editavel: true,
            },
            {
              placeholder: 'Data de Fabricação',
              name: 'DataFabricacao',
              type: 'date',
              editavel: true,
            },
          ]}
          showDelete={true}
          handleDelete={removeProdutoCarrinho}
        />
      </Card>
    </>
  );
}

export default EditarMovimentacoesEstoque;
