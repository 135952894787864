import {
    Box,
    Flex,
    Grid,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from '@chakra-ui/react';
import { Field, FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import Botao from '../../components/Botao/Botao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './OrdensCompra.css';

function EditarOrdensCompra() {
  const navigate = useNavigate();
  const [ordensCompra, setOrdensCompra] = useState({});
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const optionsStatus = [
    'Pendente',
    'Em Andamento',
    'Concluída',
    'Entregue',
    'Cancelada',
  ];
  const optionsCondicoesPagamento = [
    'Condições de Pagamento | Dinheiro  | 1X | 15 Dias',
    'Condições de Pagamento | Debito Conta | 1X | 5 Dias',
    'Condições de Pagamento | Boleto | 6X | 30 Dias',
    'Condições de Pagamento | Cartão de Dábito | 1X | 1 Meses',
    'Condições de Pagamento | PIX | 1X | 1 Meses',
    'Condições de Pagamento | Dinheiro | 1X | 1 Meses',
    'Condições de Pagamento | Dinheiro | 1X | 0 Dias',
    'Condições de Pagamento | Bolrto | 1X | 15 Dias',
    'Boleto 30, 60, 90 | Boleto | 3X | 30 Dias',
    '30D | Boleto | 1X | 30 Dias',
    'Condições de Pagamento | Cartão de Crédito | 12X | 1 Meses',
  ];

  let { id } = useParams();

  useEffect(() => {
    async function GetOrdensCompra() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditOrdensCompra/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setOrdensCompra(data.Ordem);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetOrdensCompra();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditOrdensCompra/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  const getProducts = async produto => {
    let payload = {
      data: produto,
      database: localStorage.getItem('database'),
    };
    const { data } = await api.post('/PDV/GetProdutoTodosTipos', payload);
    return data;
  };

  const calculaCarrinho = formik => {
    let total = 0;
    formik.values.Produtos.forEach((produto, index) => {
      let valor = produto.ValorUnitario * produto.Quantidade;
      formik.setFieldValue(`Produtos[${index}].ValorTotal`, valor);
      total += valor;
    });
    formik.setFieldValue('ValorTotal', total);
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <Formik
          enableReinitialize={true}
          initialValues={
            Object.keys(ordensCompra).length > 0
              ? ordensCompra
              : {
                  Codigo: '',
                  Status: '',
                  Empresa: '',
                  Fornecedor: '',
                  Transportadora: '',
                  Data: '',
                  PrevisaoEntrega: '',
                  CondicaoPagamento: '',
                  Produtos: [],
                }
          }
          onSubmit={async values => {
            setOrdensCompra(values);
            await save(JSON.stringify(values, null, 2));
          }}
        >
          {formik => (
            <Form className="body">
              <Flex
                flexGrow={1}
                id="content"
                justifyContent="center"
                minHeight="100%"
              >
                <Grid
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={'10px'}
                  mx={6}
                  width="100%"
                  height="fit-content"
                >
                  <Box
                    p={4}
                    gridColumn={gridColumn.gc12}
                    className="searchbuttons"
                  >
                    {/* <InputSearch
                      placeholder="Procurar por ordens de compra"
                      style={{ flex: 1 }}
                    /> */}
                    <Botao
                      variant="vermelho"
                      onClick={() => navigate('/OrdensCompra/Listar')}
                    >
                      Cancelar
                    </Botao>
                    <Botao variant="turquesa" type="submit">
                      Salvar
                    </Botao>
                  </Box>
                  <Box
                    mb={7}
                    pb={10}
                    gridColumn={gridColumn.gc12}
                    className="card"
                  >
                    <Flex flexGrow={1} id="content" justifyContent="center">
                      <Grid
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={'10px'}
                        width="100%"
                        height="fit-content"
                      >
                        <SecaoCadastro titulo="NOVA ORDEM DE COMPRA" />
                        <Box p={2} gridColumn={gridColumn.gc4}>
                          <p>Código</p>
                          <InputNumInt
                            id="Codigo"
                            name="Codigo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Codigo}
                          />
                          {formik.touched.Codigo && formik.errors.Codigo ? (
                            <div>{formik.errors.Codigo}</div>
                          ) : null}
                        </Box>
                        <Box p={2} gridColumn={gridColumn.gc4}>
                          <p>Status</p>
                          <InputSelect
                            id="Status"
                            name="Status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Status}
                            options={optionsStatus}
                          />
                          {formik.touched.Status && formik.errors.Status ? (
                            <div>{formik.errors.Status}</div>
                          ) : null}
                        </Box>
                        <Box p={2} gridColumn={gridColumn.gc4}>
                          <p>Empresa</p>
                          <InputAutoComplete
                            id="Empresa"
                            name="Empresa"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Empresa}
                            path="Empresa"
                            onSourceSelect={formik.setFieldValue}
                            database={localStorage.getItem('database')}
                          />
                          {formik.touched.Empresa && formik.errors.Empresa ? (
                            <div>{formik.errors.Empresa}</div>
                          ) : null}
                        </Box>
                        <Box p={2} gridColumn={gridColumn.gc4}>
                          <p>Fornecedor</p>
                          <InputAutoComplete
                            id="Fornecedor"
                            name="Fornecedor"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Fornecedor}
                            path="Fornecedor"
                            onSourceSelect={formik.setFieldValue}
                            database={localStorage.getItem('database')}
                          />
                          {formik.touched.Fornecedor &&
                          formik.errors.Fornecedor ? (
                            <div>{formik.errors.Fornecedor}</div>
                          ) : null}
                        </Box>
                        <Box p={2} gridColumn={gridColumn.gc4}>
                          <p>Transportadora</p>
                          <InputAutoComplete
                            id="Transportadora"
                            name="Transportadora"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Transportadora}
                            path="Transportadora"
                            onSourceSelect={formik.setFieldValue}
                            database={localStorage.getItem('database')}
                          />
                          {formik.touched.Transportadora &&
                          formik.errors.Transportadora ? (
                            <div>{formik.errors.Transportadora}</div>
                          ) : null}
                        </Box>
                        <Box p={2} gridColumn={gridColumn.gc4}>
                          <p>Data</p>
                          <InputData
                            id="Data"
                            name="Data"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Data}
                          />
                          {formik.touched.Data && formik.errors.Data ? (
                            <div>{formik.errors.Data}</div>
                          ) : null}
                        </Box>
                        <Box p={2} gridColumn={gridColumn.gc3}>
                          <p>Previsão de Entrega</p>
                          <InputData
                            id="PrevisaoEntrega"
                            name="PrevisaoEntrega"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.PrevisaoEntrega}
                          />
                          {formik.touched.PrevisaoEntrega &&
                          formik.errors.PrevisaoEntrega ? (
                            <div>{formik.errors.PrevisaoEntrega}</div>
                          ) : null}
                        </Box>
                        <Box p={2} gridColumn={gridColumn.gc3}>
                          <p>Condições de pagamento</p>
                          <InputSelect
                            id="CondicaoPagamento"
                            name="CondicaoPagamento"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CondicaoPagamento}
                            options={optionsCondicoesPagamento}
                          />
                          {formik.touched.CondicaoPagamento &&
                          formik.errors.CondicaoPagamento ? (
                            <div>{formik.errors.CondicaoPagamento}</div>
                          ) : null}
                        </Box>

                        <Box p={2} gridColumn={gridColumn.gc12}>
                          <FieldArray
                            name="Produtos"
                            render={arrayHelpers => (
                              <Grid
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={'10px'}
                                width="100%"
                                height="fit-content"
                              >
                                <Box p={2} gridColumn={gridColumn.gc8}>
                                  <p>Produto</p>
                                  <InputAutoComplete
                                    id="ProdutosTemp"
                                    name="ProdutosTemp"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ProdutosTemp}
                                    path="Produto"
                                    onSourceSelect={formik.setFieldValue}
                                    database={localStorage.getItem('database')}
                                  />
                                </Box>
                                <Box p={2} gridColumn={gridColumn.gc4}>
                                  <button
                                    type="button"
                                    className="buttonAddCompra"
                                    onClick={async () => {
                                      let data = await getProducts(
                                        formik.values.ProdutosTemp
                                      );
                                      if (data.Success) {
                                        var prod = data.Produtos;
                                        var achou = false;
                                        for (
                                          var i = 0;
                                          i < formik.values.Produtos.length;
                                          i++
                                        ) {
                                          if (
                                            formik.values.Produtos[i].Nome ===
                                            prod.Nome
                                          ) {
                                            achou = true;
                                            formik.values.Produtos[
                                              i
                                            ].Quantidade += 1;
                                          }
                                        }

                                        if (!achou) {
                                          var pnovo = {};
                                          pnovo.Nome = prod.Nome;
                                          pnovo.Quantidade = 1;
                                          pnovo.Detalhes = '';
                                          pnovo.ValorUnitario = prod.PrecoVenda;
                                          pnovo.ValorTotal =
                                            prod.PrecoVenda * 1;
                                          pnovo.ProdutoId = prod.Id;
                                          arrayHelpers.push(pnovo);
                                        }
                                        calculaCarrinho(formik);
                                        formik.setFieldValue(
                                          'ProdutosTemp',
                                          ''
                                        );
                                      }
                                    }}
                                  >
                                    <AiOutlinePlusCircle />
                                  </button>
                                </Box>
                                {formik.values.Produtos &&
                                formik.values.Produtos.length > 0 ? (
                                  <Box p={4} gridColumn={gridColumn.gc12}>
                                    <TableContainer>
                                      <Table>
                                        <Thead>
                                          <Tr>
                                            <Th>CÓD PRODUTO</Th>
                                            <Th>VALOR UNIT</Th>
                                            <Th>QTDE</Th>
                                            <Th>SUBTOTAL</Th>
                                          </Tr>
                                        </Thead>
                                        <Tbody>
                                          {formik.values.Produtos.map(
                                            (prod, index) => (
                                              <Tr key={index}>
                                                <Td>
                                                  <Field
                                                    name={`Produtos.${index}.Nome`}
                                                  />
                                                </Td>
                                                <Td>
                                                  <Field
                                                    name={`Produtos.${index}.Quantidade`}
                                                  />
                                                </Td>
                                                <Td>
                                                  <Field
                                                    name={`Produtos.${index}.ValorUnitario`}
                                                  />
                                                </Td>
                                                <Td>
                                                  <Field
                                                    name={`Produtos.${index}.ValorTotal`}
                                                  />
                                                </Td>
                                                <Td>
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      arrayHelpers.remove(index)
                                                    } // remove a friend from the list
                                                  >
                                                    -
                                                  </button>
                                                </Td>
                                              </Tr>
                                            )
                                          )}
                                        </Tbody>
                                      </Table>
                                    </TableContainer>
                                  </Box>
                                ) : (
                                  <div> </div>
                                )}
                              </Grid>
                            )}
                          />
                        </Box>
                      </Grid>
                    </Flex>
                  </Box>
                </Grid>
              </Flex>
            </Form>
          )}
        </Formik>
      </Pagina>
    </>
  );
}

export default EditarOrdensCompra;
