import { Box } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineDashboard, AiOutlineSetting } from 'react-icons/ai';
import {
  BiBarChartAlt,
  BiDesktop,
  BiIdCard,
  BiLogOutCircle,
  BiShoppingBag,
} from 'react-icons/bi';
import { BsTools } from 'react-icons/bs';
import { FaBoxes } from 'react-icons/fa';
import { GrNotes } from 'react-icons/gr';
import { MdOutlineSell } from 'react-icons/md';
import { TbBuildingFactory2 } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import { gridColumn } from '../../utils/gridColumn';
import { logout } from '../../utils/utils';
import ItemMenu from './ItemMenu/ItemMenu';
import ItemMenuConfig from './ItemMenuConfig/ItemMenuConfig';
import './MenuLateral.css';

function MenuLateral() {
  return (
    <Box gridColumn={gridColumn.gc3} className="menulateral">
      <div className="linksmenu">
        <Link to="/dashboard" className="linkmenu">
          <AiOutlineDashboard className="icone" />
          Dashboard
        </Link>
        <ItemMenu
          titulo={'Financeiro'}
          Icone={BiBarChartAlt}
          submenus={[
            { nome: 'Nova Movimentação', url: '/Lancamentos/Editar' },
            { nome: 'Movimentações Financeiras', url: '/Lancamentos/Listar' },
            { nome: 'DRE Gerencial', url: '/DRE/Reportar' },
            { nome: 'Boletos', url: '/Boletos/Listar' },
            { nome: 'Cheques', url: '/Cheques/Listar' },
            { nome: 'Contratos', url: '/Contratos/Listar' },
            {
              nome: 'Transferências Bancárias',
              url: '/Transferenciasbancarias/Listar',
            },
          ]}
          relatorios={[
            { nome: 'Rel. Fluxo de Caixa', url: '/FluxoCaixa/Reportar' },
            { nome: 'Rel. Faturamento', url: '/Faturamento/Reportar' },
            { nome: 'Rel. Lançamentos', url: '/Lancamentos/Reportar' },
            { url: '/PrevisaoCaixa/Reportar', nome: 'Rel. Previsão de Caixa' },
            {
              nome: 'Rel. Síntese Financeira',
              url: '/SinteseFinanceira/Reportar',
            },
            {
              nome: 'Rel. Lançamentos Não Pagos',
              url: '/LancamentosNaoPagos/Reportar',
            },
            {
              nome: 'Rel. Lançamentos Vencidos',
              url: '/LancamentosVencidos/Reportar',
            },
            { nome: 'Rel. Inadimplentes', url: '/Inadimplentes/Reportar' },
            { nome: 'Rel. Cheques', url: '/Cheques/Reportar' },
            { nome: 'Rel. Centro de Custos', url: '/CentroCustos/Reportar' },
            { nome: 'Rel. Comissões', url: '/Comissoes/Reportar' },
            { url: '/FluxoBancario/Reportar', nome: 'Rel. Fluxo Bancário' },
          ]}
        />
        <ItemMenu
          titulo={'Vendas'}
          Icone={MdOutlineSell}
          submenus={[
            { nome: 'Novo Pedido', url: '/Pedidos/Editar' },
            { nome: 'Pedidos', url: '/Pedidos/Listar' },
            { nome: 'Clientes', url: '/Clientes/Listar' },
            { nome: 'Frente de Caixa', url: '/frente-de-caixa' },
          ]}
          relatorios={[
            {
              nome: 'Rel. Orçamentos',
              url: '/Orcamentos/Reportar',
            },
            {
              nome: 'Rel. Validade de Pedidos',
              url: '/ValidadePedidos/Reportar',
            },
            { nome: 'Rel. Lucro Pedidos', url: '/LucroPedidos/Reportar' },
            {
              nome: 'Rel. Faturamento de Vendas',
              url: '/FaturamentoVendas/Reportar',
            },
            {
              nome: 'Rel. Entrega de Pedidos',
              url: '/EntregaPedidos/Reportar',
            },
            { nome: 'Rel. Pedidos PDV', url: '/PedidosPDV/Reportar' },
            { nome: 'Rel. Fechamento PDV', url: '/FechamentoPDV/Reportar' },
            { nome: 'Rel. Clientes', url: '/Clientes/Reportar' },
          ]}
        />
        <ItemMenu
          titulo={'Serviços'}
          Icone={BsTools}
          submenus={[
            { nome: 'Nova OS', url: '/OrdensServico/Editar' },
            { nome: 'Ordens de Serviço', url: '/OrdensServico/Listar' },
            { nome: 'Serviços', url: '/Servicos/Listar' },
            { nome: 'Agenda', url: '/agenda' },
            { nome: 'Equipamentos', url: '/Equipamentos/Listar' },
          ]}
          relatorios={[
            { nome: 'Rel. Ordens de Serviço', url: '/OrdensServico/Reportar' },
          ]}
        />
        <ItemMenu
          titulo={'Fiscal'}
          Icone={GrNotes}
          submenus={[
            { nome: 'Notas Fiscais', url: '/NFe/Listar' },
            { nome: 'Nova NFe', url: '/NFe/Editar' },
            { nome: 'NFCe', url: '/NFCe/Listar' },
            { nome: 'Cartas de Correção', url: '/CartasCorrecao/Listar' },
          ]}
          relatorios={[{ nome: 'Rel. Notas Fiscais', url: '' }]}
        />
        <ItemMenu
          titulo={'Estoque'}
          Icone={FaBoxes}
          submenus={[
            { nome: 'Estoques', url: '/Estoques/Listar' },
            {
              nome: 'Movimentações de Estoque',
              url: '/MovimentacoesEstoque/Listar',
            },
          ]}
          relatorios={[
            { nome: 'Rel. Estoque', url: '/Estoque/Reportar' },
            { nome: 'Rel. Valor Estoque', url: '/ValorEstoque/Reportar' },
            { nome: 'Rel. Inventário', url: '/Inventario/Reportar' },
          ]}
        />
        <ItemMenu
          titulo={'Produção'}
          Icone={TbBuildingFactory2}
          submenus={[
            { nome: 'Ordens de Produção', url: '/OrdensProducao/Listar' },
          ]}
          relatorios={[
            {
              nome: 'Rel. Ordens de Produção',
              url: '/OrdensProducao/Reportar',
            },
          ]}
        />
        <ItemMenu
          titulo={'Compras'}
          Icone={BiShoppingBag}
          submenus={[
            { nome: 'Notas de Compra', url: '/NotasCompra/Listar' },
            { nome: 'Ordens de Compra', url: '/OrdensCompra/Listar' },
            { nome: 'Consulta Notas CNPJ', url: '/consulta-notas-cnpj' },
          ]}
          relatorios={[
            {
              nome: 'Rel. Notas de Compra',
              url: '/NotasCompra/Reportar',
            },
          ]}
        />
        <ItemMenu
          titulo={'RH'}
          Icone={BiIdCard}
          submenus={[
            { nome: 'Funcionários', url: '/Funcionarios/Listar' },
            { nome: 'Arquivos RH', url: '/ArquivosRH/Listar' },
          ]}
        />
        <ItemMenu
          titulo={'Cadastros'}
          Icone={BiDesktop}
          submenus={[
            { nome: 'Clientes', url: '/Clientes/Listar' },
            { nome: 'Produtos', url: '/Produtos/Listar' },
            { nome: 'Fornecedores', url: '/Fornecedores/Listar' },
            { nome: 'Transportadoras', url: '/Transportadoras/Listar' },
            { nome: 'Vendedores', url: '/Vendedores/Listar' },
            { nome: 'Tabela de Preços', url: '/TabelasPreco/Listar' },
            { nome: 'Veículos', url: '/Veiculos/Listar' },
          ]}
        />
      </div>
      <div className="linksmenubaixo">
        <ItemMenuConfig
          titulo={'CONFIGURAÇÕES'}
          Icone={AiOutlineSetting}
          itens={[
            {
              nome: 'Empresa',
              itens: [{ nome: 'Empresas', url: '/Empresas/Listar' }],
            },
            {
              nome: 'Financeiro',
              itens: [
                { nome: 'Bancos', url: '/Bancos/Listar' },
                { nome: 'Plano de Contas', url: '/PlanoContas/Listar' },
                { nome: 'Grupos DRE', url: '/GruposDRE/Listar' },
                { nome: 'Centros de Custo', url: '/CentrosCustos/Listar' },
              ],
            },
            {
              nome: 'Vendas',
              itens: [
                {
                  nome: 'PDV (+ Balanca)',
                  url: '/ConfiguracoesBalanca/Editar',
                },
                {
                  nome: 'Condicoes de Pagamento',
                  url: '/CondicoesPagamento/Listar',
                },
                { nome: 'Config. PDV', url: '/PDV/Config' },
                {
                  nome: 'Categorias de Vendas',
                  url: '/CategoriasVendas/Listar',
                },
              ],
            },
            {
              nome: 'Produtos',
              itens: [
                { nome: 'Marcas', url: '/Marcas/Listar' },
                { nome: 'Categorias', url: '/Categorias/Listar' },
                { nome: 'Grade', url: '/GradeProdutos/Listar' },
              ],
            },
            {
              nome: 'Fiscal',
              itens: [
                { nome: 'Operações Fiscais', url: '/OperacoesFiscais/Listar' },
                {
                  nome: 'Situação Tributária',
                  url: '/SituacaoTributaria/Listar',
                },
              ],
            },
            {
              nome: 'Usuários',
              itens: [{ nome: 'Usuários', url: '/Usuarios/Listar' }],
            },
          ]}
        />
        <button type="button" onClick={() => logout()} className="linkmenu">
          <BiLogOutCircle className="logout" />
          SAIR DO SISTEMA
        </button>
        <ColorModeSwitcher />
      </div>
    </Box>
  );
}

export default MenuLateral;
