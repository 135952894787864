import { Box, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './GradeProdutos.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import TabelaInterna from '../../components/Tabela/TabelaInterna';
import Botao from '../../components/Botao/Botao';

function EditarGradeProdutos() {
  const navigate = useNavigate();
  let { id } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  const [gradeProdutos, setGradeProdutos] = useState({
    Nome: '',
    Variacoes: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function GetGradeProdutos() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditGradeProdutos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setGradeProdutos(data.Grade);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetGradeProdutos();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditGradeProdutos/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={gradeProdutos}
          onSubmit={async values => {
            setGradeProdutos(values);
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno navigate={navigate} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate }) {
  const { values, setFieldValue } = useFormikContext();
  let titulo = `${values.Id !== '' ? 'Editar' : 'Nova'} Categoria`;

  function getMaiorIdVariacoes() {
    var maior = values.Variacoes.length;
    for (var i = 0; i < values.Variacoes.length; i++) {
      if (Number(values.Variacoes[i].Id) > maior) {
        maior = Number(values.Variacoes[i].Id) + 1;
      }
    }
    return maior;
  }

  function handleClick() {
    let items = values.Variacoes;
    items.push({
      Id: getMaiorIdVariacoes().toString(),
      Nome: '',
    });
    setFieldValue('Variacoes', items);
  }

  function removeVariacao(item, index) {
    let items = values.Variacoes;
    items.splice(index, 1);
    setFieldValue('Variacoes', items);
  }

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/GradeProdutos/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Grades', path: '/GradeProdutos/Listar' },
          { nome: titulo },
        ]}
      />
      <Card titulo={titulo}>
        <InputFormik
          titulo="Nome"
          id="Nome"
          name="Nome"
          component={InputTexto}
          gridColumn={gridColumn.gc6}
        />
        <Box p={2} gridColumn={gridColumn.gc6}>
          <br></br>
          <Botao variant="verde" type="button" onClick={() => handleClick()}>
            ADICIONAR VARIAÇÃO
          </Botao>
        </Box>
        <TabelaInterna
          nomeLista="Variacoes"
          columns={[
            {
              placeholder: 'Variação',
              name: 'Nome',
              type: 'text',
              editavel: true,
            },
          ]}
          showDelete={true}
          handleDelete={removeVariacao}
        />
      </Card>
    </>
  );
}

export default EditarGradeProdutos;
