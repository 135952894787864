import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputDinheiro from '../../components/Inputs/InputDinheiro/InputDinheiro';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './Cheques.css';

function EditarCheques() {
  const navigate = useNavigate();

  const [cheque, setCheque] = useState({
    Banco: null,
    BancoId: null,
    Agencia: 0,
    Conta: 0,
    Numero: 18,
    DataEmissao: '0001-01-01',
    DataCompensacao: '0001-01-01',
    DataBaixado: '0001-01-01',
    Valor: 0,
    Favorecido: null,
    FavorecidoId: null,
    Emitente: null,
    EmitenteId: null,
    Tipo: null,
    Situacao: null,
    Observacoes: null,
    Id: null,
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetCheques() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditCheques/Get', payload);

      if (res.status === 200) {
        let data = res.data;
        setCheque(data.Cheque);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetCheques();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditCheques/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={cheque}
          onSubmit={async values => {
            setCheque(values);
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno navigate={navigate} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate, ...props }) {
  const tiposDeCheque = [
    { value: '', label: 'Selecione' },
    { value: 'Deposito', label: 'Depósito' },
    { value: 'Reapresentacao', label: 'Reapresentação' },
    { value: 'Devolucao', label: 'Devolução' },
    { value: 'Custodia', label: 'Custódia' },
  ];

  const tiposDeSituacao = [
    { value: '', label: 'Selecione' },
    { value: 'EmCaixa', label: 'Em Caixa' },
    { value: 'Depositado', label: 'Depositado' },
    { value: 'Devolvido', label: 'Devolvido' },
    { value: 'Guardado', label: 'Guardado' },
  ];

  const formik = useFormikContext();
  let titulo = `${formik.values.Id !== '' ? 'Editar' : 'Novo'
    } Cheque`;

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/Cheques/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Cheques', path: '/Cheques/Listar' },
          { nome: titulo },
        ]}
      />

      <Card titulo="NOVO CHEQUE">
        <InputFormik
          titulo="Número"
          id="Numero"
          name="Numero"
          component={InputNumInt}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Emitente"
          id="Emitente"
          name="Emitente"
          component={InputAutoComplete}
          path="ClienteFornecedor"
          database={localStorage.getItem('database')}
          gridColumn={gridColumn.gc4}
          autocomplete={true}
        />
        <InputFormik
          titulo="Tipo"
          id="Tipo"
          name="Tipo"
          component={InputSelect}
          options={tiposDeCheque}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Banco"
          id="Banco"
          name="Banco"
          component={InputAutoComplete}
          path="Bancos"
          database={localStorage.getItem('database')}
          gridColumn={gridColumn.gc4}
          autocomplete={true}
        />
        <InputFormik
          titulo="Favorecido"
          id="Favorecido"
          name="Favorecido"
          component={InputAutoComplete}
          path="Empresa"
          database={localStorage.getItem('database')}
          gridColumn={gridColumn.gc4}
          autocomplete={true}
        />
        <InputFormik
          titulo="Situação"
          id="Situacao"
          name="Situacao"
          component={InputSelect}
          options={tiposDeSituacao}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Emissão"
          id="DataEmissao"
          name="DataEmissao"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Compensação"
          id="DataCompensacao"
          name="DataCompensacao"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Baixado"
          id="DataBaixado"
          name="DataBaixado"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Agência"
          id="Agencia"
          name="Agencia"
          component={InputNumInt}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Conta"
          id="Conta"
          name="Conta"
          component={InputNumInt}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Valor"
          id="Valor"
          name="Valor"
          component={InputDinheiro}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Observações"
          id="Observacoes"
          name="Observacoes"
          component={InputTextArea}
          gridColumn={gridColumn.gc4}
        />
      </Card>
    </>
  );
}

export default EditarCheques;
