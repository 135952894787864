import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import Botao from '../../components/Botao/Botao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './NotasCompra.css';

function EditarNotasCompra() {
  const navigate = useNavigate();
  const optionsOperacao = [
    '1-Compra',
    '2-Devolução',
    '5-Transferência de Entrada',
    '8-CIAP',
    '17-Acerto de Estoque',
  ];
  const optionsRegEmitente = [
    'Simples Nacional',
    'Simples Nacional-excesso de sublimite de receita bruta',
    'Regime Normal.(v2.0)',
  ];
  const optionsCentroCusto = [
    'Jurídico',
    'Juros Bancário',
    'Salário',
    'Despesas Escritório',
    'Comissões',
    'Receitas Avulsas',
  ];
  const optionsPagamento = [
    'Dinheiro',
    'Carnê',
    'Cartão de Débito',
    'Cartão de Crédito',
    'Cheque',
  ];
  const [notasCompra, setNotasCompra] = useState({});
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetNotasCompra() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditNotasCompra/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setNotasCompra(data.NotaCompra);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetNotasCompra();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditNotasCompra/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      Object.keys(notasCompra).length > 0
        ? notasCompra
        : {
            Codigo: '',
            Empresa: '',
            Deposito: '',
            Fornecedor: '',
            DataEmissaoNota: '',
            DataEntradaNota: '',
            SerieNota: '',
            NumeroNota: '',
            ChaveAcesso: '',
            ValorTotalDaNota: '',
            ValorNota: '',
            Desconto: '',
            Despesas: '',
            ICMS_STValor: '',
            CFOP: '',
            RegimeTributarioEmitente: '',
            PlanoDeConta: '',
            CentroCustos: '',
            FormaPagamento: '',
            OrdemCodigo: '',
            NotaSemItens: '',
            NaoMovimentarEstoque: '',
            Descricao: '',
            Quantidade: '',
            Parcelado: '',
            PISBase: '',
            COFINSBase: '',
            ICMSBase: '',
            IPIBase: '',
            PISValor: '',
            COFINSValor: '',
            ICMSValor: '',
            IPIValor: '',
            InformacoesAdicionais: '',
          },
    validationSchema: yup.object({
      // Codigo: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Empresa: yup.string().required('O campo é obrigatório.').nullable(),
      // Deposito: yup.string().required('O campo é obrigatório.').nullable(),
      // Fornecedor: yup.string().required('O campo é obrigatório.').nullable(),
      // DataEmissaoNota: yup.date().required('O campo é obrigatório.').nullable(),
      // DataEntradaNota: yup.date().required('O campo é obrigatório.').nullable(),
      // SerieNota: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // NumeroNota: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ChaveAcesso: yup.string().required('O campo é obrigatório.').nullable(),
      // ValorTotalDaNota: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ValorNota: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Desconto: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Despesas: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ICMS_STValor: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // CFOP: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // RegimeTributarioEmitente: yup.string().required('O campo é obrigatório.').nullable(),
      // PlanoDeConta: yup.string().required('O campo é obrigatório.').nullable(),
      // CentroCustos: yup.string().required('O campo é obrigatório.').nullable(),
      // FormaPagamento: yup.string().required('O campo é obrigatório.').nullable(),
      // OrdemCodigo: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // NotaSemItens: yup.boolean().required('O campo é obrigatório.').nullable(),
      // NaoMovimentarEstoque: yup.boolean().required('O campo é obrigatório.').nullable(),
      // Descricao: yup.string().required('O campo é obrigatório.').nullable(),
      // Quantidade: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Parcelado: yup.boolean().required('O campo é obrigatório.').nullable(),
      // PISBase: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // COFINSBase: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ICMSBase: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // IPIBase: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // PISValor: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // COFINSValor: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ICMSValor: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // IPIValor: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // InformacoesAdicionais: yup.string().required('O campo é obrigatório.').nullable(),
    }),
    onSubmit: async values => {
      setNotasCompra(values);
      await save(JSON.stringify(values, null, 2));
    },
  });

  const CounterContext = React.createContext();
  const CounterProvider = ({ children }) => {
    const [counter, setCounter] = React.useState(1);

    return (
      <CounterContext.Provider value={{ counter, setCounter }}>
        {children}
      </CounterContext.Provider>
    );
  };

  const Counter = () => {
    const context = React.useContext(CounterContext);
    const handleIncrement = () => context.setCounter(state => state + 1);
    const handleDecrement = () => context.setCounter(state => state - 1);
    const incrementAmount = () =>
      context.setCounter(state => (state += InputNumInt.value));

    return (
      <div className="buttonsIncDecNC">
        <label htmlFor="incrementAmountNC">
          Quantidade
          <input
            id="incrementAmountNC"
            type="number"
            onChange={({ target }) => incrementAmount(target.value)}
            value={context.counter || context.amount}
          />
        </label>
        <button type="button" onClick={handleIncrement}>
          <AiOutlinePlusCircle className="botaoPlusNC" />
        </button>
        <button type="button" onClick={handleDecrement}>
          <AiOutlineMinusCircle className="botaoMinusNC" />
        </button>
        <button
          type="button"
          className="buttonAddNC"
          onClick={() => context.setCounter(context.counter)}
        >
          ADICIONAR
        </button>
      </div>
    );
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <form className="body" onSubmit={formik.handleSubmit}>
          <Flex
            flexGrow={1}
            id="content"
            justifyContent="center"
            minHeight="100%"
          >
            <Grid
              gridTemplateColumns="repeat(12, 1fr)"
              gap={'10px'}
              mx={6}
              width="100%"
              height="fit-content"
            >
              <Box p={4} gridColumn={gridColumn.gc12} className="searchbuttons">
                {/* <InputSearch placeholder="Procurar por notas de compra" style={{ flex: 1 }} />  */}
                <Botao variant="rosa">Importar XML</Botao>
                <Botao
                  variant="vermelho"
                  onClick={() => navigate('/NotasCompra/Listar')}
                >
                  Cancelar
                </Botao>
                <Botao variant="turquesa" type="submit">
                  Salvar
                </Botao>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="NOTA DE COMPRA" />
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Código</p>
                      <InputNumInt
                        id="Codigo"
                        name="Codigo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Codigo}
                      />
                      {formik.touched.Codigo && formik.errors.Codigo ? (
                        <div>{formik.errors.Codigo}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Empresa</p>
                      <InputAutoComplete
                        id="Empresa"
                        name="Empresa"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Empresa}
                      />
                      {formik.touched.Empresa && formik.errors.Empresa ? (
                        <div>{formik.errors.Empresa}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Depósito</p>
                      <InputAutoComplete
                        id="Deposito"
                        name="Deposito"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Deposito}
                      />
                      {formik.touched.Deposito && formik.errors.Deposito ? (
                        <div>{formik.errors.Deposito}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Fornecedor</p>
                      <InputTexto
                        id="Fornecedor"
                        name="Fornecedor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Fornecedor}
                      />
                      {formik.touched.Fornecedor && formik.errors.Fornecedor ? (
                        <div>{formik.errors.Fornecedor}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Emissão</p>
                      <InputData
                        id="DataEmissaoNota"
                        name="DataEmissaoNota"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DataEmissaoNota}
                      />
                      {formik.touched.DataEmissaoNota &&
                      formik.errors.DataEmissaoNota ? (
                        <div>{formik.errors.DataEmissaoNota}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Entrada de Itens</p>
                      <InputData
                        id="DataEntradaNota"
                        name="DataEntradaNota"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DataEntradaNota}
                      />
                      {formik.touched.DataEntradaNota &&
                      formik.errors.DataEntradaNota ? (
                        <div>{formik.errors.DataEntradaNota}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Série da Nota</p>
                      <InputTexto
                        id="SerieNota"
                        name="SerieNota"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.SerieNota}
                      />
                      {formik.touched.SerieNota && formik.errors.SerieNota ? (
                        <div>{formik.errors.SerieNota}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Número da Nota</p>
                      <InputNumInt
                        id="NumeroNota"
                        name="NumeroNota"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.NumeroNota}
                      />
                      {formik.touched.NumeroNota && formik.errors.NumeroNota ? (
                        <div>{formik.errors.NumeroNota}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Chave de Acesso</p>
                      <InputTexto
                        id="ChaveAcesso"
                        name="ChaveAcesso"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ChaveAcesso}
                      />
                      {formik.touched.ChaveAcesso &&
                      formik.errors.ChaveAcesso ? (
                        <div>{formik.errors.ChaveAcesso}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="DADOS DA NOTA" />
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Valor Total</p>
                      <InputAutoComplete
                        id="ValorTotalDaNota"
                        name="ValorTotalDaNota"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ValorTotalDaNota}
                      />
                      {formik.touched.ValorTotalDaNota &&
                      formik.errors.ValorTotalDaNota ? (
                        <div>{formik.errors.ValorTotalDaNota}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Valor dos Itens</p>
                      <InputAutoComplete
                        id="ValorNota"
                        name="ValorNota"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ValorNota}
                      />
                      {formik.touched.ValorNota && formik.errors.ValorNota ? (
                        <div>{formik.errors.ValorNota}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Descontos</p>
                      <InputNumInt
                        id="Desconto"
                        name="Desconto"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Desconto}
                      />
                      {formik.touched.Desconto && formik.errors.Desconto ? (
                        <div>{formik.errors.Desconto}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Despesas</p>
                      <InputNumInt
                        id="Despesas"
                        name="Despesas"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Despesas}
                      />
                      {formik.touched.Despesas && formik.errors.Despesas ? (
                        <div>{formik.errors.Despesas}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>ICMS ST Pago</p>
                      <InputNumInt
                        id="ICMS_STValor"
                        name="ICMS_STValor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ICMS_STValor}
                      />
                      {formik.touched.ICMS_STValor &&
                      formik.errors.ICMS_STValor ? (
                        <div>{formik.errors.ICMS_STValor}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>CFOP</p>
                      <InputAutoComplete
                        id="CFOP"
                        name="CFOP"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.CFOP}
                      />
                      {formik.touched.CFOP && formik.errors.CFOP ? (
                        <div>{formik.errors.CFOP}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Operação</p>
                      <InputSelect
                        id="Nome"
                        name="Nome"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Nome}
                        options={optionsOperacao}
                      />
                      {formik.touched.Nome && formik.errors.Nome ? (
                        <div>{formik.errors.Nome}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Regime Emitente</p>
                      <InputSelect
                        id="RegimeTributarioEmitente"
                        name="RegimeTributarioEmitente"
                        options={optionsRegEmitente}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.RegimeTributarioEmitente}
                      />
                      {formik.touched.RegimeTributarioEmitente &&
                      formik.errors.RegimeTributarioEmitente ? (
                        <div>{formik.errors.RegimeTributarioEmitente}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Plano de Contas</p>
                      <InputAutoComplete
                        id="PlanoDeConta"
                        name="PlanoDeConta"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PlanoDeConta}
                      />
                      {formik.touched.PlanoDeConta &&
                      formik.errors.PlanoDeConta ? (
                        <div>{formik.errors.PlanoDeConta}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Centro de Custos</p>
                      <InputSelect
                        id="CentroCustos"
                        name="CentroCustos"
                        options={optionsCentroCusto}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.CentroCustos}
                      />
                      {formik.touched.CentroCustos &&
                      formik.errors.CentroCustos ? (
                        <div>{formik.errors.CentroCustos}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Forma de Pagamento</p>
                      <InputSelect
                        id="FormaPagamento"
                        name="FormaPagamento"
                        options={optionsPagamento}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.FormaPagamento}
                      />
                      {formik.touched.FormaPagamento &&
                      formik.errors.FormaPagamento ? (
                        <div>{formik.errors.FormaPagamento}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Ordem de Compra</p>
                      <InputTexto
                        id="OrdemCodigo"
                        name="OrdemCodigo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.OrdemCodigo}
                      />
                      {formik.touched.OrdemCodigo &&
                      formik.errors.OrdemCodigo ? (
                        <div>{formik.errors.OrdemCodigo}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Nota de Entrada Sem itens?</p>
                      <InputToggle
                        id="NotaSemItens"
                        name="NotaSemItens"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.NotaSemItens}
                      />
                      {formik.touched.NotaSemItens &&
                      formik.errors.NotaSemItens ? (
                        <div>{formik.errors.NotaSemItens}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Não movimentar estoque</p>
                      <InputToggle
                        id="NaoMovimentarEstoque"
                        name="NaoMovimentarEstoque"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.NaoMovimentarEstoque}
                      />
                      {formik.touched.NaoMovimentarEstoque &&
                      formik.errors.NaoMovimentarEstoque ? (
                        <div>{formik.errors.NaoMovimentarEstoque}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="PRODUTOS" />
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>Produto/Serviço</p>
                      <InputAutoComplete
                        id="Descricao"
                        name="Descricao"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Descricao}
                      />
                      {formik.touched.Descricao && formik.errors.Descricao ? (
                        <div>{formik.errors.Descricao}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <CounterProvider>
                        <Counter
                          id="Quantidade"
                          name="Quantidade"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Quantidade}
                        />
                        {formik.touched.Quantidade &&
                        formik.errors.Quantidade ? (
                          <div>{formik.errors.Quantidade}</div>
                        ) : null}
                      </CounterProvider>
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="PARCELAMENTO" />
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>Parcelar?</p>
                      <InputToggle
                        id="Parcelado"
                        name="Parcelado"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Parcelado}
                      />
                      {formik.touched.Parcelado && formik.errors.Parcelado ? (
                        <div>{formik.errors.Parcelado}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="IMPOSTOS" />
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>PIS BC</p>
                      <InputAutoComplete
                        id="PISBase"
                        name="PISBase"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PISBase}
                      />
                      {formik.touched.PISBase && formik.errors.PISBase ? (
                        <div>{formik.errors.PISBase}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>COFINS BC</p>
                      <InputAutoComplete
                        id="COFINSBase"
                        name="COFINSBase"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.COFINSBase}
                      />
                      {formik.touched.COFINSBase && formik.errors.COFINSBase ? (
                        <div>{formik.errors.COFINSBase}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>ICMS BC</p>
                      <InputAutoComplete
                        id="ICMSBase"
                        name="ICMSBase"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ICMSBase}
                      />
                      {formik.touched.ICMSBase && formik.errors.ICMSBase ? (
                        <div>{formik.errors.ICMSBase}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>IPI BC</p>
                      <InputAutoComplete
                        id="IPIBase"
                        name="IPIBase"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.IPIBase}
                      />
                      {formik.touched.IPIBase && formik.errors.IPIBase ? (
                        <div>{formik.errors.IPIBase}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>PIS</p>
                      <InputAutoComplete
                        id="PISValor"
                        name="PISValor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PISValor}
                      />
                      {formik.touched.PISValor && formik.errors.PISValor ? (
                        <div>{formik.errors.PISValor}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>COFINS</p>
                      <InputAutoComplete
                        id="COFINSValor"
                        name="COFINSValor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.COFINSValor}
                      />
                      {formik.touched.COFINSValor &&
                      formik.errors.COFINSValor ? (
                        <div>{formik.errors.COFINSValor}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>ICMS</p>
                      <InputAutoComplete
                        id="ICMSValor"
                        name="ICMSValor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ICMSValor}
                      />
                      {formik.touched.ICMSValor && formik.errors.ICMSValor ? (
                        <div>{formik.errors.ICMSValor}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>IPI</p>
                      <InputAutoComplete
                        id="IPIValor"
                        name="IPIValor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.IPIValor}
                      />
                      {formik.touched.IPIValor && formik.errors.IPIValor ? (
                        <div>{formik.errors.IPIValor}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="INFORMAÇÕES ADICIONAIS" />
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>Observações</p>
                      <InputTextArea
                        id="InformacoesAdicionais"
                        name="InformacoesAdicionais"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.InformacoesAdicionais}
                      />
                      {formik.touched.InformacoesAdicionais &&
                      formik.errors.InformacoesAdicionais ? (
                        <div>{formik.errors.InformacoesAdicionais}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
            </Grid>
          </Flex>
        </form>
      </Pagina>
    </>
  );
}

export default EditarNotasCompra;
