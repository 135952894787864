import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputDinheiro from '../../components/Inputs/InputDinheiro/InputDinheiro';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './Bancos.css';
import {
  optionsCarteira,
  optionsContas,
  optionsNumBancos,
  optionsVariacaoCarteira,
  valor_inicial,
} from './utils';

function EditarBancos() {
  const navigate = useNavigate();
  const [banco, setBanco] = useState(valor_inicial);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetBancos() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditBancos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setBanco(data.Banco);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetBancos();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditBancos/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <div>
      <>
        <Alerta
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          alertStatus={alertStatus}
          title={alertTitle}
        />
        <Pagina loading={loading}>
          <FormikPadrao
            enableReinitialize={true}
            initialValues={banco}
            validationSchema={yup.object({
              //   // Nome: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
              //   // TipoConta: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
              //   // CodigoBancoCorrespondente: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
              //   // EmiteBoletos: yup.boolean('O campo é obrigatório').required('O campo é obrigatório').nullable(),
              //   // Empresa: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
              //   // SaldoInicial: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
              //   // Agencia: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
              //   // DigitoAgencia: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
              //   // Conta: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
              //   // DigitoConta: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
            })}
            onSubmit={async values => {
              setBanco(values);
              await save(JSON.stringify(values, null, 2));
            }}
          >
            <Interno navigate={navigate} />
          </FormikPadrao>
        </Pagina>
      </>
    </div>
  );
}

function Interno({ navigate }) {
  const formik = useFormikContext();
  let titulo = `${formik.values.Id !== '' ? 'Editar' : 'Novo'} Banco`;

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/Bancos/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[{ nome: 'Bancos', path: '/Bancos/Listar' }, { nome: titulo }]}
      />

      <Card titulo="NOVO BANCO">
        <InputFormik
          titulo="Nome"
          id="Nome"
          name="Nome"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Tipo de Conta"
          id="TipoConta"
          name="TipoConta"
          component={InputSelect}
          gridColumn={gridColumn.gc4}
          options={optionsContas}
        />
        <InputFormik
          titulo="Número Banco"
          id="CodigoBancoCorrespondente"
          name="DetalhesConta.CodigoBancoCorrespondente"
          component={InputSelect}
          gridColumn={gridColumn.gc4}
          options={optionsNumBancos}
        />
        <InputFormik
          titulo="Emite Boletos?"
          id="EmiteBoletos"
          name="EmiteBoletos"
          component={InputToggle}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Empresa"
          id="Empresa"
          name="Empresa"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Empresa"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Saldo Inicial"
          id="SaldoInicial"
          name="SaldoInicial"
          component={InputDinheiro}
          gridColumn={gridColumn.gc4}
          options={optionsContas}
        />
        <InputFormik
          titulo="Agência"
          id="Agencia"
          name="DetalhesConta.Agencia"
          component={InputNumInt}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Dígito Agência"
          id="DigitoAgencia"
          name="DetalhesConta.DigitoAgencia"
          component={InputNumInt}
          gridColumn={gridColumn.gc2}
        />
        <InputFormik
          titulo="Número Conta"
          id="Conta"
          name="DetalhesConta.Conta"
          component={InputNumInt}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Dígito Conta"
          id="DigitoConta"
          name="DetalhesConta.DigitoConta"
          component={InputNumInt}
          gridColumn={gridColumn.gc2}
        />
      </Card>
      {formik.values.EmiteBoletos && (
        <Card titulo="CONFIGURAÇÕES BOLETOS">
          <InputFormik
            titulo="Carteira"
            id="CarteiraPadrao"
            name="DetalhesConta.CarteiraPadrao"
            component={InputSelect}
            gridColumn={gridColumn.gc4}
            options={
              optionsCarteira[
                formik.values.DetalhesConta.CodigoBancoCorrespondente
              ]
            }
          />
          {formik.values.DetalhesConta.CodigoBancoCorrespondente === 1 && (
            <InputFormik
              titulo="Variação Carteira"
              id="VariacaoCarteiraPadrao"
              name="DetalhesConta.VariacaoCarteiraPadrao"
              component={InputSelect}
              gridColumn={gridColumn.gc4}
              options={optionsVariacaoCarteira}
            />
          )}
          <InputFormik
            titulo="Código Cedente"
            id="CodigoCedente"
            name="CodigoCedente"
            component={InputNumInt}
            gridColumn={gridColumn.gc4}
          />
          <InputFormik
            titulo="Dígito Cedente"
            id="DigitoCedente"
            name="DigitoCedente"
            component={InputNumInt}
            gridColumn={gridColumn.gc4}
          />
          <InputFormik
            titulo="Nosso Número Inicial"
            id="NossoNumeroInicial"
            name="NossoNumeroInicial"
            component={InputNumInt}
            gridColumn={gridColumn.gc4}
          />
          <InputFormik
            titulo="Mensagem Fixa Sacado"
            id="MensagemFixaSacado"
            name="DetalhesConta.MensagemFixaSacado"
            component={InputTexto}
            gridColumn={gridColumn.gc4}
          />
          <InputFormik
            titulo="Mensagem Fixa Topo Boleto"
            id="MensagemFixaTopoBoleto"
            name="DetalhesConta.MensagemFixaTopoBoleto"
            component={InputTexto}
            gridColumn={gridColumn.gc4}
          />
        </Card>
      )}
    </>
  );
}

export default EditarBancos;
