import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import InputDinheiro from './../../components/Inputs/InputDinheiro/InputDinheiro';
import './TransferenciasBancarias.css';

function EditarTransferenciasBancarias() {
  const navigate = useNavigate();
  const [transferencia, setTransferencia] = useState({
    Codigo: '',
    DataCompetencia: '',
    Valor: 0,
    EmpresaOrigem: '',
    BancoOrigem: '',
    PlanoContasOrigem: '',
    EmpresaDestino: '',
    BancoDestino: '',
    PlanoContasDestino: '',
    Observacoes: '',
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetTransferencias() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditTransferenciasBancarias/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setTransferencia(data.Transferencias);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetTransferencias();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditTransferenciasBancarias/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={transferencia}
          validationSchema={yup.object({
            // Codigo: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
            // DataCompetencia: yup.date().required('O campo é obrigatório.').nullable(),
            // Valor: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
            // EmpresaOrigem: yup.string().required('O campo é obrigatório.').nullable(),
            // BancoOrigem: yup.string().required('O campo é obrigatório.').nullable(),
            // PlanoContasOrigem: yup.string().required('O campo é obrigatório.').nullable(),
            // EmpresaDestino: yup.string().required('O campo é obrigatório.').nullable(),
            // BancoDestino: yup.string().required('O campo é obrigatório.').nullable(),
            // PlanoContasDestino: yup.string().required('O campo é obrigatório.').nullable(),
            // Observacoes: yup.string().required('O campo é obrigatório.').nullable(),
          })}
          onSubmit={async values => {
            setTransferencia(values);
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno navigate={navigate} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate, ...props }) {
  const formik = useFormikContext();
  let titulo = `${
    formik.values.Id !== '' ? 'Editar' : 'Nova'
  } Transferência Bancária`;

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/TransferenciasBancarias/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Transferências', path: '/TransferenciasBancarias/Listar' },
          { nome: titulo },
        ]}
      />

      <Card titulo={titulo}>
        <InputFormik
          titulo="Código"
          id="Codigo"
          name="Codigo"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Competência"
          id="DataCompetencia"
          name="DataCompetencia"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Valor"
          id="Valor"
          name="Valor"
          component={InputDinheiro}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Empresa de Origem"
          id="EmpresaOrigem"
          name="EmpresaOrigem"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Empresa"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Banco de Origem"
          id="BancoOrigem"
          name="BancoOrigem"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Bancos"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Plano de Contas de Origem"
          id="PlanoContasOrigem"
          name="PlanoContasOrigem"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="PlanosContas"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Empresa de Destino"
          id="EmpresaDestino"
          name="EmpresaDestino"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Empresa"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Banco de Destino"
          id="BancoDestino"
          name="BancoDestino"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Bancos"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Plano de Contas de Destino"
          id="PlanoContasDestino"
          name="PlanoContasDestino"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="PlanosContas"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Observações"
          id="Observacoes"
          name="Observacoes"
          component={InputTextArea}
          gridColumn={gridColumn.gc12}
        />
      </Card>
    </>
  );
}

export default EditarTransferenciasBancarias;
