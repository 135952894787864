import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL, estados } from '../../utils/utils';
import BarraBotoes from './../../components/BarraBotoes/BarraBotoes';
import Card from './../../components/Card/Card';
import InputFloat from './../../components/Inputs/InputFloat/InputFloat';
import InputFormik from './../../components/Inputs/InputFormik/InputFormik';
import './Boletos.css';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';

function EditarBoletos() {
  const navigate = useNavigate();
  const [boletos, setBoletos] = useState({
    Banco: '',
    Empresa: '',
    Cliente: '',
    CPFCNPJ: '',
    Nome: '',
    Observacoes: '',
    LogradouroEndereco: '',
    LogradouroNumero: '',
    Bairro: '',
    Cidade: '',
    UF: '',
    CEP: '',
    DataEmissao: '',
    DataVencimento: '',
    ValorTitulo: 0,
    NossoNumero: 0,
    NumeroDocumento: 0,
    DataDesconto: '',
    ValorDesconto: 0,
    DataMulta: '',
    ValorMulta: 0,
    PercentualMulta: 0,
    DataJuros: '',
    ValorJuros: 0,
    PercentualJuros: 0,
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetBoletos() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditBoletos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setBoletos(data.Boleto);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetBoletos();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditBoletos/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  async function gerarPDF(boleto) {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(boleto, null, 2),
    };
    let res = await api.post('/EditBoletos/GerarPDF', payload);

    if (res.status === 200) {

      if (res.data.Success) {
        var pdf = res.data.BoletoPDF;

        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "boleto.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      else {
        setAlertStatus('error');
        setAlertTitle(res.data.MSG);
        onOpen();
      }
      setLoading(false);
    } else {
      alert('Erro ao gerar remessa');

    }
  }


  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={boletos}
          // validationSchema={ }
          onSubmit={async values => {
            setBoletos(values);
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno
            navigate={navigate}
            loading={loading}
            setLoading={setLoading}
            gerarPDF={gerarPDF}
          />
        </FormikPadrao>
      </Pagina>
    </>
  );


}

function Interno({ navigate, gerarPDF }) {
  const { values, setFieldValue } = useFormikContext();



  useEffect(() => {
    async function AtualizarNossoNumero() {
      let payload = {
        database: localStorage.getItem('database'),
        data: JSON.stringify(values.Banco, null, 2),
      };
      let res = await api.post('/EditBoletos/GetNossoNumero', payload);
      if (res.status === 200) {
        setFieldValue("Boleto.NossoNumero", res.data.Retorno);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    AtualizarNossoNumero()
  }, [values.Banco, setFieldValue])

  useEffect(() => {
    async function getDadosCNPJ() {
      let payload = {
        database: localStorage.getItem('database'),
        data: values.Cliente,
      };
      let res = await api.post('/EditClientes/GetByName', payload);
      if (res.status === 200) {
        var data = res.data;
        if (data.Encontrado) {
          if (data.Cliente.CPF_CNPJ !== "") {
            setFieldValue("Boleto.Sacado.CPFCNPJ", data.Cliente.CPF_CNPJ);
          }
          if (data.Cliente.NomeFantasia !== "") {
            setFieldValue("Boleto.Sacado.Nome", data.Cliente.NomeFantasia);
          }
          if (data.Cliente.UFEndereco !== "") {
            setFieldValue("Boleto.Sacado.Endereco.UF", data.Cliente.UFEndereco);
          }
          if (data.Cliente.BairroEndereco !== "") {
            setFieldValue("Boleto.Sacado.Endereco.Bairro", data.Cliente.BairroEndereco);
          }
          if (data.Cliente.RuaEndereco !== "") {
            setFieldValue("Boleto.Sacado.Endereco.LogradouroEndereco", data.Cliente.RuaEndereco);
          }
          if (data.Cliente.NumeroEndereco !== "") {
            setFieldValue("Boleto.Sacado.Endereco.LogradouroNumero", data.Cliente.NumeroEndereco);
          }
          if (data.Cliente.CEPEndereco !== "") {
            setFieldValue("Boleto.Sacado.Endereco.CEP", data.Cliente.CEPEndereco);
          }
          if (data.Cliente.CidadeEndereco !== "") {
            setFieldValue("Boleto.Sacado.Endereco.Cidade", data.Cliente.CidadeEndereco);
          }
        }
        setFieldValue("Boleto.NossoNumero", res.data.Retorno);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    getDadosCNPJ()
  }, [values.Cliente, setFieldValue])




  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/Boletos/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'amarelo',
            onClick: () => gerarPDF(values),
            children: 'Baixar PDF',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },

        ]}
      />
      <Card
        titulo={values.Id == null ? 'Editar Boleto' : 'Novo Boleto'}
      >
        <InputFormik
          titulo="Banco"
          id="Banco"
          name="Banco"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Bancos"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />

        <InputFormik
          titulo="Empresa"
          id="Empresa"
          name="Empresa"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Empresa"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />

        <InputFormik
          titulo="Cliente"
          id="Cliente"
          name="Cliente"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Cliente"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />

        <InputFormik
          titulo="CPF/CNPJ"
          id="Boleto.Sacado.CPFCNPJ"
          name="Boleto.Sacado.CPFCNPJ"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Nome"
          id="Boleto.Sacado.Nome"
          name="Boleto.Sacado.Nome"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Observações"
          id="Boleto.Sacado.Observacoes"
          name="Boleto.Sacado.Observacoes"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Logradouro"
          id="Boleto.Sacado.Endereco.LogradouroEndereco"
          name="Boleto.Sacado.Endereco.LogradouroEndereco"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Número"
          id="Boleto.Sacado.Endereco.LogradouroNumero"
          name="Boleto.Sacado.Endereco.LogradouroNumero"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Bairro"
          id="Boleto.Sacado.Endereco.Bairro"
          name="Boleto.Sacado.Endereco.Bairro"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Cidade"
          id="Boleto.Sacado.Endereco.Cidade"
          name="Boleto.Sacado.Endereco.Cidade"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="UF"
          id="Boleto.Sacado.Endereco.UF"
          name="Boleto.Sacado.Endereco.UF"
          component={InputSelect}
          options={estados}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="CEP"
          id="Boleto.Sacado.Endereco.CEP"
          name="Boleto.Sacado.Endereco.CEP"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Data Emissao"
          id="Boleto.DataEmissao"
          name="Boleto.DataEmissao"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
      </Card>
      <Card titulo="DADOS DO BOLETO">
        <InputFormik
          titulo="Data Vencimento"
          id="Boleto.DataVencimento"
          name="Boleto.DataVencimento"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Valor Título"
          id="Boleto.ValorTitulo"
          name="Boleto.ValorTitulo"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Nosso Número"
          id="Boleto.NossoNumero"
          name="Boleto.NossoNumero"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Número Documento"
          id="Boleto.NumeroDocumento"
          name="Boleto.NumeroDocumento"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Data Desconto"
          id="Boleto.DataDesconto"
          name="Boleto.DataDesconto"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Valor Desconto"
          id="Boleto.ValorDesconto"
          name="Boleto.ValorDesconto"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Data Multa"
          id="Boleto.DataMulta"
          name="Boleto.DataMulta"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Valor Multa"
          id="Boleto.ValorMulta"
          name="Boleto.ValorMulta"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Percentual Multa"
          id="Boleto.PercentualMulta"
          name="Boleto.PercentualMulta"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Data Juros"
          id="Boleto.DataJuros"
          name="Boleto.DataJuros"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Valor Juros"
          id="Boleto.ValorJuros"
          name="Boleto.ValorJuros"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Percentual Juros"
          id="Boleto.PercentualJuros"
          name="Boleto.PercentualJuros"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />
      </Card>
    </>
  );
}

export default EditarBoletos;
